import axios from "axios";
import qs from "qs";
import { ORDER_API_URL, ORDER_API_SUB_KEY, GET_ORDER_EP, UPD_ORDER_EP, SENDER_ID } from "./const";

const axiosInstance = axios.create({
  baseURL: ORDER_API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": ORDER_API_SUB_KEY,
    "SenderID": SENDER_ID,
    "TargetID": "OGSI",
    "BusinessGroup": "GRP",
    Pragma: "no-cache",
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
  timeout: 240000,
});

export const getOrder = async ( OrderNumber, DestinationSystem, RequestId = "") => {
  try {
    const response = await axiosInstance.get(`${GET_ORDER_EP}?OriginatingSystem=MPADJT&DestinationSystem=${DestinationSystem}&OrderNumber=${OrderNumber}&RequestId=${RequestId}`);
    return response;
  } catch (error) {
    // Handle error or propagate it
    console.log('Error fetching order status:', error);
    throw error;
  }
};

export const patchOrder = async (payload) => {
  try {
    const response = await axiosInstance.patch(UPD_ORDER_EP, payload);
    return response;
  } catch (error) {
    console.log('Error upadting order status:', error);
    throw error;
  }
};