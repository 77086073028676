import { combineReducers } from "redux";
import searchReducer from "../search/searchReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import decReducer from "../modDec/decReducer";
import qteReducer from "../qtedet/qteReducer";

const persistConfig = {
  key: 'root',
  storage
};


const rootReducer = combineReducers({
  search: persistReducer(persistConfig,searchReducer),
  dec: decReducer,
  qte: qteReducer
});

export default rootReducer;
//export default persistReducer(persistConfig,rootReducer);
