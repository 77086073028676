import * as types from "./searchTypes";

export const initialState = {
  phase: types.LOADING,
  email:"",
  stransType: "",
  sorgId: "",
  sorgname: "",
  squoteno: "",
  squotever: "",
  sordernbr: "",
  sadjustment: "",
  errors: null,
  showQuote: false,
  showOrder: false,
  showAccord : true,
  ous: [],
  adjs: [],
  loadingOU: false,
  loadingAdj: false,
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.IS_QUOTE:
      return {
        ...state,
        stransType: action.payload,
        showQuote: true,
        showOrder: false,
      };
    case types.IS_ORDER:
      return {
        ...state,
        stransType: action.payload,
        showQuote: false,
        showOrder: true,
      };
    case types.GET_OU_START:
      return {
        ...state,
        stransType:action.payload.stransType,
        // sorgId: action.payload.sorgId,
        // showQuote: action.payload.showQuote,
        // showOrder: action.payload.showOrder,
        loadingOU: true,
        phase: types.LOADING,
      };
    case types.GET_OU_SUCCESS:
      return {
        ...state,
        phase: types.SUCCESS,
        loadingOU: false,
        ous: action.ous,
        sorgId: action.sorgId,
        squoteno:action.squoteno,
        sorgname: action.sorgname,
        showQuote: action.showQuote,
        showOrder: action.showOrder,
        squotever:action.squotever,
        sordernbr: action.sordernbr,
      };      
    case types.GET_OU_FAILURE:
      return {
        ...state,
        phase: types.ERROR,
        loadingOU: false,
        errors: action.payload,
      };
    case types.GET_ADJ_START:
      return {
        ...state,
        sorgId: action.payload.sorgId,
        sorgname: action.payload.sorgname,
        adjs: [],
        phase: types.LOADING,
        loadingAdj: true,
      };
    case types.GET_ADJ_SUCCESS:
      return {
        ...state,
        adjs: action.adjs,
        sorgId: action.sorgId,
        //sorgname: action.sorgname,
        phase: types.SUCCESS,
        loadingAdj: false,
      };
    case types.GET_ADJ_FAILURE:
      return {
        ...state,
        phase: types.ERROR,
        errors: action.payload,
        loadingAdj: false,
      };
      case types.SET_EMAIL:
        return {
          ...state,
          email: action.email.email,
        };
      case types.SET_ACCORD :
        return {
          ...state,
          showAccord : action.showAccord,
        }       
    default:
      return state;
  }
};

export default searchReducer;
