const url = window.location.host;
const isLocalhost = url.includes("localhost");
const isDev = url.includes("ssocq-dev");
const isStage = url.includes("ssocq-stg");
const isProd = url.includes("ssocq.emerson.com") || url.includes("ssocq.azurewebsites.net");

let API_URL;
let API_SUB_KEY;
let ORDER_API_URL;
let ORDER_API_SUB_KEY;
let GET_ORDER_EP;
let UPD_ORDER_EP;
let SENDER_ID;

if (isLocalhost) {
  // S12 Quote
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/GQFAPI/v1";
  API_SUB_KEY = "b2762515fb2d4950aa9aafe4bbb93173";

  //D4 Quote
  // API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/dev/GQFAPI/v1";
  // API_SUB_KEY = "3afd2dae0efb484b907a69b632dd976a"

  //K1 Order
  ORDER_API_URL = "https://sandbox.gateway.emerson.com/api/asldpis";
  ORDER_API_SUB_KEY = "78845fbaabe047b8934ce18c5dc329c9";

  GET_ORDER_EP = "/os-p-dev/v1/orderstatus";
  UPD_ORDER_EP = "/salesorder-dev/v1/salesorder";

  SENDER_ID = "GD";
} else if (isDev) {
  //D4 Quote
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/dev/GQFAPI/v1";
  API_SUB_KEY = "3afd2dae0efb484b907a69b632dd976a";

  //K1 Order
  ORDER_API_URL = "https://sandbox.gateway.emerson.com/api/asldpis";
  ORDER_API_SUB_KEY = "78845fbaabe047b8934ce18c5dc329c9";

  GET_ORDER_EP = "/os-p-dev/v1/orderstatus";
  UPD_ORDER_EP = "/salesorder-dev/v1/salesorder";

  SENDER_ID = "GD";
} else if (isStage) {
  //S12
  API_URL = "https://sandbox.gateway.emerson.com/api/aslgrp/GQFAPI/v1";
  API_SUB_KEY = "b2762515fb2d4950aa9aafe4bbb93173";

  ORDER_API_URL = "https://sandbox.gateway.emerson.com/api/asldpis";
  ORDER_API_SUB_KEY = "d422b530eb06435a877e4a14912e9018";

  GET_ORDER_EP = "/os-p/v1/orderstatus";
  UPD_ORDER_EP = "/salesorder/v1/salesorder";

  SENDER_ID = "AUTOBOT";
} else if (isProd) {
  //PROD
  API_URL = "https://apim.gateway.emerson.com/api/aslgrp/GQFAPI/v1";
  API_SUB_KEY = "347fadf377934d6cbd413b70aa250f09";

  ORDER_API_URL = "https://apim.gateway.emerson.com/api/asldpis";
  ORDER_API_SUB_KEY = "766793872ee248dfa2c10b565f355e5f";

  GET_ORDER_EP = "/os-p/v1/orderstatus";
  UPD_ORDER_EP = "/salesorder/v1/salesorder";

  SENDER_ID = "AUTOBOT";
}

export {
  API_URL,
  API_SUB_KEY,
  ORDER_API_URL,
  ORDER_API_SUB_KEY,
  GET_ORDER_EP,
  UPD_ORDER_EP,
  SENDER_ID
};
