//import React from 'react';
import { SHOW_DECISSION } from "./decTypes";

const initialState = {
  decission: false,
  showText: 'Please close the Global Quote Form if  you want to proceed?'
};


const decReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DECISSION:
      return {
        ...state,
        decission: action.decission,
      };
    default:
      return state;
  }
};

export default decReducer;