export const IS_QUOTE = "IS_QUOTE";
export const IS_ORDER = "IS_ORDER";
export const GET_OU_START = "GET_OU_START";
export const GET_OU_SUCCESS = "GET_OU_SUCCESS";
export const GET_OU_FAILURE = "GET_OU_FAILURE";
export const GET_ADJ_START ="GET_ADJ_START";
export const GET_ADJ_SUCCESS ="GET_ADJ_SUCCESS";
export const GET_ADJ_FAILURE ="GET_ADJ_FAILURE";
export const LOADING ='LOADING';
export const SUCCESS = 'SUCCESS';
export const ERROR = 'ERROR';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_ACCORD = 'SET_ACCORD';
