import { takeLatest } from "@redux-saga/core/effects";
import { call } from "redux-saga/effects";
import { SHOW_DECISSION } from "./decTypes";
import { showDec } from "./decActions";

export function* onDecMadeSync(action) {  
  try {
    yield call(showDec, action.decission);
  } catch (e) {
    
  }
}

export function* onDecMade() {
  yield takeLatest(SHOW_DECISSION, onDecMadeSync);
}
