export const FETCH_QTE_DET_START = "FETCH_QTE_DET_START";
export const FETCH_QTE_DET_SUCCESS = "FETCH_QTE_DET_SUCCESS";
export const FETCH_QTE_DET_ERROR = "FETCH_QTE_DET_ERROR";
export const LOADING = "LOADING";
export const SUCCESS = "SUCCESS";
export const ERROR = "ERROR";
export const UPD_QTE_DET_START = "UPD_QTE_DET_START";
export const UPD_QTE_DET_SUCCESS = "UPD_QTE_DET_SUCCESS";
export const UPD_QTE_DET_ERROR = "UPD_QTE_DET_ERROR";
export const APPLY_MASSADJ_START = "APPLY_MASSADJ_START";
export const APPLY_MASSADJ_SUCCESS = "APPLY_MASSADJ_SUCCESS";
export const APPLY_MASSADJ_ERROR = "APPLY_MASSADJ_ERROR";
export const REFRESH_CONC_START = "REFRESH_CONC_START";
export const REFRESH_CONC_SUCCESS = "REFRESH_CONC_SUCCESS";
export const REFRESH_CONC_ERROR = "REFRESH_CONC_ERROR";
export const DELETE_QTE_ADJ_START = 'DELETE_QTE_ADJ_START';
export const DELETE_QTE_ADJ_SUCCESS = 'DELETE_QTE_ADJ_SUCCESS';
export const DELETE_QTE_ADJ_FAILURE = 'DELETE_QTE_ADJ_FAILURE';
export const GET_SELSECTED_ADJ = "GET_SELSECTED_ADJ";

//for order
export const FETCH_ODR_START = "FETCH_ODR_START";
export const FETCH_ODR_SUCCESS = "FETCH_ODR_SUCCESS";
export const FETCH_ODR_ERROR = "FETCH_ODR_ERROR";
export const UPD_ODR_START = "UPD_ODR_START";
export const UPD_ODR_SUCCESS = "UPD_ODR_SUCCESS";
export const UPD_ODR_ERROR = "UPD_ODR_ERROR";
export const REFRESH_ODR_START = "REFRESH_ODR_START";
export const REFRESH_ODR_SUCCESS = "REFRESH_ODR_SUCCESS";
export const REFRESH_ODR_ERROR = "REFRESH_ODR_ERROR";