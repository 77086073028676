import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import logo from "../images/emerson-logo.png";
import CustomToolTip from "./CustomToolTip";
import CustomIconButton from "./CustomIconButton";
import LogoutIcon from "../shared/icons/LogoutIcon";
import LoginIcon from "../shared/icons/LoginIcon"
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const CustomHeaderMui = ({ setCorsErrorModalOpen, title }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const isCorsError = (err) =>
    err.name === "AuthApiError" &&
    !err.errorCode &&
    err.xhr.message === "Failed to fetch";
  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: window.location.href });
  };

  const logout = async () => {
    const basename = window.location.origin + "/";
    try {
      await oktaAuth.revokeAccessToken().catch((err) => {
        // console.log(err);
      });
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: "#00aa7e" }}>
          <Toolbar
            sx={{
              justifyContent: "space-between",
              minHeight: "60px !important",
              height: "60px",
            }}
          >
            <img src={logo} alt="logo" className="logo" height="60px" />
            <Typography
              variant="h5"
              component="h6"
              sx={{
                fontFamily: "Arial",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
                textTransform: "uppercase",
              }}
            >
              {title ? title : ""}
              {/* Mass Price Adjustments */}
            </Typography>
            {authState.isAuthenticated && (
            <CustomToolTip title="Logout" placement="bottom">
              <span>
                <CustomIconButton onClick={logout}>
                  <LogoutIcon />
                </CustomIconButton>
              </span>
            </CustomToolTip>
            )}
            {!authState.isPending && !authState.isAuthenticated && (
            <CustomToolTip title="Login" placement="bottom">
              <span>
                <CustomIconButton onClick={login}>
                  <LoginIcon />
                </CustomIconButton>
              </span>
            </CustomToolTip>
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
};

export default CustomHeaderMui;
