import { takeLatest, put, call } from "@redux-saga/core/effects";
import {
  loadOUFailure,
  loadOUSuccess,
  loadAdjSuccess,
  loadAdjFailure,setEmail,
} from "./searchActions";
import * as types from "./searchTypes";
import { getOUsAPI, fetchAdjs } from "../../store/apis/api";


export function* onLoadGetOUsAsync(action) {
  try {
    const response = yield call(
      getOUsAPI,
      action.payload.sorgId,
      action.payload.showQuote,
      action.payload.showOrder
    );
    yield put(
      loadOUSuccess(
        response,
        action.payload.sorgId,
        response.length === 1 ? response[0].NAME : "",
        action.payload.squoteno,
        action.payload.showQuote,
        action.payload.showOrder,
        action.payload.squotever,
        action.payload.sordernbr,
      )
    );
  } catch (e) {
    yield put(loadOUFailure(e));
  }
}

export function* onLoadOUs() {
  yield takeLatest(types.GET_OU_START, onLoadGetOUsAsync);
}

export function* onLoadAdjsAsync(action) {
  try {
    const response = yield call(
      fetchAdjs,
      action.payload.sorgId,
      action.payload.showQuote,
      action.payload.showOrder
    );

    yield put(
      loadAdjSuccess(response, action.payload.sorgId, action.payload.sorgname)
    );
  } catch (e) {
    yield put(loadAdjFailure(e));
  }
}

export function* onLoadAdjs() {
  yield takeLatest(types.GET_ADJ_START, onLoadAdjsAsync);
  
}


export function* onSSOLoginSync(action){
  try {
    yield call(setEmail, action.email.email);
  } catch (e) {
    
  }
}

export function* onSSOLogin(){
  yield takeLatest(types.SET_EMAIL, onSSOLoginSync);
}