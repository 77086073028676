import axios from "axios";
import qs from "qs";
import { API_URL, API_SUB_KEY } from "./const";

const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Ocp-Apim-Subscription-Key": API_SUB_KEY,
    Pragma: "no-cache",
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, { arrayFormat: "brackets" });
  },
  timeout: 240000,
});

export const getOUsAPI = (I_ORG_ID) =>
  axiosInstance
    .post(
      "/fetch_ous",
      // {
      //   IP: {
      //     P_ORG_ID: P_ORG_ID == null ? "" : P_ORG_ID,
      //   },
      // }
      {
        XXASO_GQF_CUSTOM_API_PKG_INPUT: {
          InputParameters: {
            I_ORG_ID: I_ORG_ID == null ? "" : I_ORG_ID,
          },
        },
      }
    )
    .then(
      (response) =>
        response.data.OutputParameters.P_TYPE_OU_DETAIL_OUT
          .P_TYPE_OU_DETAIL_OUT_ITEM
    );

export const fetchAdjs = (I_ORG_ID, isQuote) => {
  const payload = {
    XXASO_GQF_CUSTOM_API_PKG_INPUT: {
      InputParameters: {
        I_ORG_ID: I_ORG_ID == null ? "" : I_ORG_ID,
        // ...(!isQuote && { "Application_Code": "OM" }) //sending extra param incase of order //not required
      },
    },
  }
  return (
    axiosInstance
      .post(
        "/fetch_adjs", payload
      )
      .then(
        (response) =>
          response.data.OutputParameters.P_TYPE_ADJUSTMENT_OUT
            .P_TYPE_ADJUSTMENT_OUT_ITEM
      )
  )
}

// export const fetchAdjs = (I_ORG_ID, isQuote) => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           "ADJUSTMENT_NAME": "ISV Vendavo Proposed Discounts",
//           "ADJUSTMENT": "69696853",
//           "TYPE": "(Dis)",
//           "APPMETHOD": "(%)",
//           "ORG_ID": "1"
//         },
//         {
//           "ADJUSTMENT_NAME": "Manual Price Adjustment (JDI)",
//           "ADJUSTMENT": "30577857",
//           "TYPE": "(Sur)",
//           "APPMETHOD": "(NP)",
//           "ORG_ID": "1"
//         },
//         {
//           "ADJUSTMENT_NAME": "EPM Manual Discount",
//           "ADJUSTMENT": "54151570",
//           "TYPE": "(Dis)",
//           "APPMETHOD": "(%)",
//           "ORG_ID": "1"
//         },
//         {
//           "ADJUSTMENT_NAME": "EPM Manual Surcharge",
//           "ADJUSTMENT": "54151573",
//           "TYPE": "(Sur)",
//           "APPMETHOD": "(%)",
//           "ORG_ID": "1"
//         },
//         {
//           "ADJUSTMENT_NAME": "EPM Manual Discount",
//           "ADJUSTMENT": "54151571",
//           "TYPE": "(Dis)",
//           "APPMETHOD": "(AMT)",
//           "ORG_ID": "1"
//         },
//         {
//           "ADJUSTMENT_NAME": "EPM Manual Surcharge",
//           "ADJUSTMENT": "54151574",
//           "TYPE": "(Sur)",
//           "APPMETHOD": "(AMT)",
//           "ORG_ID": "1"
//         }
//       ]);
//     }, 10000);
//   });
// }


export const fetchQteDet = (P_QTE, P_VER, P_ORG_ID) =>
  axiosInstance.post(
    "/fetch_qtedets",
    //  {
    //   IP: {
    //     P_QUOTE_NUMBER: P_QTE,
    //     P_QUOTE_VERSION: P_VER,
    //     P_ORG_ID: P_ORG_ID,
    //   },
    // }
    {
      XXASO_GQF_CUSTOM_API_PKG_INPUT: {
        InputParameters: {
          P_QUOTE_NUMBER: P_QTE,
          P_QUOTE_VERSION: P_VER,
          P_ORG_ID: P_ORG_ID,
        },
      },
    }
  );

export const applyMpadjs = (payload) =>
  axiosInstance
    .post(
      "/apply_mass_adjust",
      // {
      //   IP: {
      //     I_ADJUSTMENT_INPUT: [
      //       {
      //         I_ADJUSTMENT_INPUT_ITEM: payload,
      //       },
      //     ],
      //   },
      // }
      {
        InputParameters: {
          I_ADJUSTMENT_INPUT: [
            {
              I_ADJUSTMENT_INPUT_ITEM: payload,
            },
          ],
        },
      }
    )
    .then((response) => response.data.OutputParameters.O_REQUEST_ID);

export const concStat = (P_CONC) =>
  axiosInstance
    .post("/check_conc_status", 
    // {
    //   IP: {
    //     I_CONC_REQ: P_CONC,
    //   },
    // }
    {
      XXASO_GQF_CUSTOM_API_PKG_INPUT: {
          InputParameters: {
              I_CONC_REQ: P_CONC,
          }
      }
  }
    )
    .then((response) => response.data.OutputParameters.O_COMPLETED_CODE);

export const deleteQteAPI = (payload) => {
  return axiosInstance
    .post("/Qte_Adjust_Del", 
    // {
    //   XXASO_GQF_CUSTOM_API_PKG_INPUT: 
    //   {
    //     InputParameters: {
    //       P_ADJUSTMENT_INPUT: [
    //         {
    //           P_ADJUSTMENT_INPUT_ITEM: payload,
    //         },
    //       ],
    //     },
    //   },
    // }
    {
      XXASO_GQF_CUSTOM_API_PKG_INPUT: {
        InputParameters: {
          P_ADJUSTMENT_INPUT: [
            {
              P_ADJUSTMENT_INPUT_ITEM: payload,
            },
          ],
        },
      },
    }
    
    )
    .then((response) => response.data.OutputParameters.O_ERROR_MSG);
};
