import { useOktaAuth } from '@okta/okta-react';
import React from "react";
import { useNavigate } from 'react-router-dom';
import logo from "../images/emerson-logo.png";
import "./header.scss";
// import {  Menu } from 'semantic-ui-react';
import { AiOutlineLogin, AiOutlineLogout } from "react-icons/ai";
// import AppBar from '@mui/material/AppBar';

function Header({ setCorsErrorModalOpen }) {
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();
  const isCorsError = (err) => (err.name === 'AuthApiError' && !err.errorCode && err.xhr.message === 'Failed to fetch');
  const login = async () => {
    oktaAuth.signInWithRedirect({ originalUri: window.location.href });
  };

  const logout = async () => {
    // const basename = window.location.origin + history.createHref({ pathname: '/' });
    const basename = window.location.origin +'/';
    try {
      await oktaAuth.revokeAccessToken().catch((err) => {
        // console.log(err);
      });
      await oktaAuth.signOut({ postLogoutRedirectUri: basename });
    } catch (err) {
      if (isCorsError(err)) {
        setCorsErrorModalOpen(true);
      } else {
        throw err;
      }
    }
  };

  if (!authState) {
    return null;
  }


  return (
    <React.Fragment>
      <header style={{ height: '60px', padding: '0px', margin: '0px' }}>
        <nav className="navbar navbar-expand-sm bkclr fixed-top">
          <img src={logo} alt="logo" className="logo" />
          <div className="app-title">Mass Price Adjustments</div>
          {authState.isAuthenticated && (
            <div className='divlp'>
              <label htmlFor='logoutid'> <AiOutlineLogout className="logout" data-toggle="tooltip" data-placement="top" title="Logout" /> </label>
              {/*<Menu.Item id="logout-button" onClick={logout}  className="logot1" >Logout</Menu.Item>*/}
              <button id='logoutid' onClick={logout} className="logot"></button>
            </div>
          )}
          {!authState.isPending && !authState.isAuthenticated && (
            <div className='divlp'>
              <label htmlFor='loginid'> <AiOutlineLogin className="login" data-toggle="tooltip" data-placement="top" title="Login" /> </label>
              {/*<Menu.Item onClick={login}  className="logot" >Login</Menu.Item>*/}
              <button id='loginid' onClick={login} className="logot"></button>
            </div>
          )}
        </nav>
      </header>
    </React.Fragment>
  );
}

export default Header;
