import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#00aa7e",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ececec',
      color: "#00aa7e",
      borderRadius: '2px',
      letterSpacing: '0.5px',
      border: '1px solid #00aa7e',
      marginTop: '7px !important',
      fontSize: '12px',
      fontStyle: 'italic',
      fontFamily: 'Segoe UI',
      fontWeight: 500,
    },
  }));

export default CustomToolTip