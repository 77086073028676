import { takeLatest, put, call } from "@redux-saga/core/effects";
import {
  fetchQteDetSucc,
  fetchQteDetErr,
  updQteDetSucc,
  updQteDetErr,
  applyMpadjsErr,
  applyMpadjsSucc,
  rqststatSucc,
  rqststatErr,
  deleteQteAdjSucc,
  deleteQteAdjFailure,
  fetchOrderDetSucc,
  fetchOrderDetErr,
  updateOrderSucc,
  updateOrderErr,
  refreshOrderSucc,
  refreshOrderErr
} from "./qteActions";
import { fetchQteDet, applyMpadjs, concStat, deleteQteAPI } from "../../store/apis/api";
import * as types from "./qteTypes";
import { getOrder, patchOrder } from "../../store/apis/orderApi";
import { SENDER_ID } from "../../store/apis/const";

function prepareUpdateOrderPayload(action) {

  const { isDelete, payload, sorgname, sordernbr } = action;

  const basePayload = {
    SalesOrder: {
      MessageHeader: {
        Sender: {
          ID: SENDER_ID,
        },
        Target: {
          ID: 'OGSI',
        },
        BusinessGroup: 'GRP',
      },
      DataArea: {
        SalesOrderHeader: [
          {
            OrderHeader: {
              DestinationSystem: sorgname,
              OrderNbr: sordernbr,
            },
          },
        ],
        SalesOrderLine: [],
      },
    },
  };


  if (isDelete) {
    payload.forEach((item) => {
      const lineNumber = parseInt(item.UI_LINE_NUMBER);
      const lineID = item.LINE_ID;
      const listLineId = parseFloat(item.LIST_LINE_NO);

      const adjustmentData = {
        ListLineId: listLineId,
        Operation: 'Delete',
        ChangeReasonCode: 'Manual',
        AdjustmentAmt: '',
      };

      const salesOrderLines = basePayload.SalesOrder.DataArea.SalesOrderLine;
      const lineIndex = salesOrderLines.findIndex((line) => line.OrderLine.LineID === lineID);
      if (lineIndex === -1) {
        salesOrderLines.push({
          OrderLine: {
            LineID: lineID,
            LineNbr: lineNumber,
          },
          LineAdjustment: [adjustmentData],
        });
      } else {
        salesOrderLines[lineIndex].LineAdjustment.push(adjustmentData);
      }
    });
  } else {
    const adjustments = payload.map((item) => ({
      OrderLine: {
        LineID: item.LINE_ID,
        LineNbr: parseInt(item.UI_LINE_NUMBER)
      },
      LineAdjustment: [
        {
          ListLineId: parseFloat(item.LIST_LINE_NO),
          Operation: "Apply",
          ChangeReasonCode: "Manual",
          AdjustmentAmt: parseFloat(item.OPERAND)
        }
      ]
    }))

    basePayload.SalesOrder.DataArea.SalesOrderLine = adjustments;
  }

  return basePayload;
}

export function* fetchQteAsync(action) {
  try {
    const response = yield call(
      fetchQteDet,
      action.payload.squoteno,
      action.payload.squotever,
      action.payload.sorgId
    );
    yield put(fetchQteDetSucc(response, action));
  } catch (e) {
    yield put(fetchQteDetErr(e));
  }
}

export function* fetchQte() {
  yield takeLatest(types.FETCH_QTE_DET_START, fetchQteAsync);
}

export function* updQteSync(action) {
  try {
    yield put(updQteDetSucc(action));
  } catch (e) {
    yield put(updQteDetErr(e));
  }
}

export function* updQte() {
  yield takeLatest(types.UPD_QTE_DET_START, updQteSync);
}

export function* applyMpadjsAsync(action) {
  try {
    const response = yield call(
      applyMpadjs,
      action.payload
    );
    yield put(applyMpadjsSucc(response, action));
  } catch (e) {
    yield put(applyMpadjsErr(e));
  }
}

export function* updMpadjs() {
  yield takeLatest(types.APPLY_MASSADJ_START, applyMpadjsAsync);
}

// export function* rqststatAsync(action) {

//   try {
//     const response = yield call(
//       concStat,
//       action.payload
//     );
//     console.log("response ??????",response)
//     yield put(rqststatSucc(response, action));
//   } catch (e) {
//     yield put(rqststatErr(e));
//   }
// }

export function* rqststatAsync(action) {
  try {
    while (true) {
      const response = yield call(concStat, action.payload);

      // console.log("response ??????", response);

      // Check if the response indicates completion
      if (response === "Completed") {
        yield put(rqststatSucc(response, action));
        break; // Exit the loop when "completed" is received
      }

      // Delay before making the next API call (e.g., 1 second)
      yield call(delay, 1000); // Define the delay function appropriately

      // You might also want to dispatch a progress action here if needed
    }
  } catch (e) {
    yield put(rqststatErr(e));
  }
}

// Define your delay function (or use an existing library)
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function* rqststat() {

  yield takeLatest(types.REFRESH_CONC_START, rqststatAsync);
}

export function* onDeleteQteAsync(action) {
  try {
    const response = yield call(deleteQteAPI, action.payload);
    const tempRes = response.replace(/[^0-9\.]+/g, '');
    yield put(deleteQteAdjSucc(tempRes));
  }
  catch (e) {
    yield put(deleteQteAdjFailure(e))
  }
}

export function* onDeleteQte() {
  yield takeLatest(types.DELETE_QTE_ADJ_START, onDeleteQteAsync);
}

export function* fetchOrderAsync(action) {
  try {
    const response = yield call(
      getOrder,
      action.payload.sordernbr,
      action.payload.sorgname,
      // action.payload.sorgId
    );
    yield put(fetchOrderDetSucc(response));
  } catch (e) {
    yield put(fetchOrderDetErr(e));
  }
}

export function* fetchOrder() {
  yield takeLatest(types.FETCH_ODR_START, fetchOrderAsync);
}

export function* updateOrderAsync(action) {

  const updPayload = prepareUpdateOrderPayload(action)
  try {
    const response = yield call(
      patchOrder,
      updPayload
    );
    yield put(updateOrderSucc(response));
  } catch (e) {
    yield put(updateOrderErr(e));
  }
}

export function* updateOrder() {
  yield takeLatest(types.UPD_ODR_START, updateOrderAsync);
}

export function* refreshOrderConcAsync(action) {

  try {
    while (true) {
      yield call(delay, 2000);
      const response = yield call(
        getOrder,
        '',
        action.payload.sorgname,
        action.payload.crqstId
      )
      // if (response.data.OrderStatus.DataArea.OrderStatusInfo[0].OrderHeaderInfo.OrderStatus) {
      //   yield put(fetchOrderDetSucc(response));
      //   yield put(refreshOrderSucc());
      //   break;
      // }
      if (response.data?.Response?.ResponseCode !== 500) {
        yield put(fetchOrderDetSucc(response));
        yield put(refreshOrderSucc());
        break;
      }
    }
  } 
  catch (e) {
    yield put(refreshOrderErr(e));
  }
}

export function* refreshOrderConc() {
  yield takeLatest(types.REFRESH_ODR_START, refreshOrderConcAsync)
}