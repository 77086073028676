import * as types from "./qteTypes";

function getMaxAppliedAdjLen(data) {
  return data.reduce((max, obj) => {
    const length = obj.APPLIED_ADJUSTMENT.length;
    return length > max ? length : max;
  }, 0);
}

const initialState = {
  phase: types.LOADING,
  qteOrgId: "",
  qteNbr: "",
  qteVer: "",
  qteDte: [],
  updQte: [],
  selAdj: [],
  loading: false,
  errors: null,
  rqstId: null,
  rqstErr: null,
  iniFetch: false,
  ProgressBar: false,
  maxAppliedAdjLen: 0
};

const qteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_QTE_DET_START:
      return {
        ...state,
        qteOrgId: action.payload.qteOrgId,
        qteNbr: action.payload.qteNbr,
        qteVer: action.payload.qteVer,
        qteDte: action.payload.qteDte,
        phase: types.LOADING,
        loading: true,
      };
    case types.FETCH_QTE_DET_SUCCESS:
      let newval =
        action.payload.data.OutputParameters.P_TYPE_QOT_ADJ_OUT
          .P_TYPE_QOT_ADJ_OUT_ITEM;
      newval.map((o) => {
        o["LIST_LINE_NO"] = null;
        o["OPERAND"] = null;
      });
      return {
        ...state,
        phase: types.SUCCESS,
        loading: false,
        qteDte: newval,
        iniFetch: false,
        qteNbr: action.payload.data.OutputParameters.P_TYPE_QOT_ADJ_OUT
          .P_TYPE_QOT_ADJ_OUT_ITEM[0].QUOTE_NUMBER,
        /*(action.payload.data.OutputParameters.P_TYPE_QOT_ADJ_OUT
            .P_TYPE_QOT_ADJ_OUT_ITEM)*/
        maxAppliedAdjLen: getMaxAppliedAdjLen(newval),
      };
    case types.FETCH_QTE_DET_ERROR:
      return {
        ...state,
        phase: types.ERROR,
        loading: false,
        errors: action.payload,
      };
    case types.UPD_QTE_DET_START:
      return {
        ...state,
        phase: types.LOADING,
        loading: true,
      };
    case types.UPD_QTE_DET_SUCCESS:
      return {
        ...state,
        phase: types.SUCCESS,
        loading: false,
        qteDte: action.payload.payload,
      };
    case types.UPD_QTE_DET_ERROR:
      return {
        ...state,
        phase: types.ERROR,
        loading: false,
        errors: action.payload,
      };
    case types.APPLY_MASSADJ_START:
      return {
        ...state,
        phase: types.LOADING,
        loading: true,
        updQte: action.payload,
        ProgressBar: true,
      };
    case types.APPLY_MASSADJ_SUCCESS:
      return {
        ...state,
        phase: types.SUCCESS,
        //loading: false,
        //qteDte: action.payload.payload,
        rqstId: action.payload,
      };
    case types.APPLY_MASSADJ_ERROR:
      return {
        ...state,
        phase: types.ERROR,
        loading: false,
        errors: action.payload,
      };
    case types.REFRESH_CONC_START:
      return {
        ...state,
        phase: types.LOADING,
        loading: true,
      };
    case types.REFRESH_CONC_SUCCESS:
      return {
        ...state,
        phase: types.SUCCESS,
        // loading: (action.payload !== 'Completed'? true :false),
        // rqstId: (action.payload !== 'Completed'? state.rqstId :null),
        // selAdj:(action.payload !== 'Completed'? state.selAdj :null)
        loading: false,
        rqstId: null,
        selAdj: null,
        iniFetch: true,
        ProgressBar: false,
      };
    case types.REFRESH_CONC_ERROR:
      return {
        phase: types.ERROR,
        loading: false,
        rqstErr: action.payload,
      };
    case types.DELETE_QTE_ADJ_START:
      return {
        ...state,
        phase: types.LOADING,
        loading: true,
        ProgressBar: true,
      }
    case types.DELETE_QTE_ADJ_SUCCESS:
      return {
        ...state,
        phase: types.SUCCESS,
        loading: true,
        rqstId: action.payload,
      };
    case types.DELETE_QTE_ADJ_FAILURE:
      return {
        ...state,
        phase: types.ERROR,
        loading: false,
        errors: action.payload,
      };
    case types.GET_SELSECTED_ADJ:
      return {
        ...state,
        selAdj: action.payload
      }
    case types.FETCH_ODR_START:
      return {
        ...state,
        phase: types.LOADING,
        loading: true,
      }
    case types.FETCH_ODR_SUCCESS:
      const data = action.payload.data.OrderStatus.DataArea.OrderStatusInfo[0].OrderLineInfo
      const orderNo = action.payload.data.OrderStatus.DataArea.OrderStatusInfo[0].OrderHeaderInfo.SalesOrderNumber

      const orderDte = data.reduce((acc, originalItem) => {
        const validLine = originalItem.LineStatus !== 'Cancelled'
          && originalItem.LineStatus !== 'Closed'
          && originalItem.LINEADJUSTMENT?.LINEADJUSTMENT_ITEM

        if (validLine) {
          return acc.concat(validLine
            .filter(item => !(item.UNIT_LIST_PRICE == 0 && item.UNIT_SELLING_PRICE == 0))
            .map(item => ({
              ...item,
              LIST_LINE_NO: null,
              OPERAND: null,
              ORDER_NUMBER: orderNo,
              LINE_ID: originalItem.LineID
            })));
        }
        return acc;
      }, []);

      return {
        ...state,
        qteDte: orderDte,
        phase: types.SUCCESS,
        loading: false,
        maxAppliedAdjLen: getMaxAppliedAdjLen(orderDte),
      }

    case types.FETCH_ODR_ERROR:
      return {
        ...state,
        phase: types.ERROR,
        loading: false,
        errors: action.payload,
      }

    case types.UPD_ODR_START:
      return {
        ...state,
        phase: types.LOADING,
        loading: true,
        // updQte: action.payload,
        ProgressBar: true,
      }

    case types.UPD_ODR_SUCCESS:
      return {
        ...state,
        phase: types.SUCCESS,
        rqstId: action.payload.data.Response.RequestId,
      }

    case types.UPD_ODR_ERROR:
      return {
        ...state,
        phase: types.ERROR,
        loading: false,
        errors: action.payload,
      }

    case types.REFRESH_ODR_START:
      return {
        ...state,
        phase: types.LOADING,
        loading: true,
      };

    case types.REFRESH_ODR_SUCCESS:
      return {
        ...state,
        phase: types.SUCCESS,
        loading: false,
        rqstId: null,
        selAdj: null,
        // iniFetch: true,
        ProgressBar: false,
      }

    case types.REFRESH_ODR_ERROR:
      return {
        phase: types.ERROR,
        loading: false,
        rqstErr: action.payload,
      };

    default:
      return state;
  }
};

export default qteReducer;
