import React , {lazy , Suspense} from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import config from "./config";
// import Home from "./Home";
import CorsErrorModal from "./CorsErrorModal";
import Header from "../header/header";
import CustomHeaderMui from "../materialComponent/CustomHeaderMui";

const oktaAuth = new OktaAuth(config.oidc);

const Home = lazy(() => import("./Home"));

const SSO = () => {
  const [corsErrorModalOpen, setCorsErrorModalOpen] = React.useState(false);
  const navigate = useNavigate();
  // const restoreOriginalUri = async (_oktaAuth, originalUri) => {
  //   navigate.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  // };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    if (originalUri !== undefined && window.location.origin !== undefined) {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin));
    }
  }
  

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {/* <Header {...{ setCorsErrorModalOpen }} /> */}
      <CustomHeaderMui {...{ setCorsErrorModalOpen }} title="mass price adjustments"  />
      <CorsErrorModal {...{ corsErrorModalOpen, setCorsErrorModalOpen }} />
    <Suspense fallback={<div>Loading</div>}>
      <Routes>
        {/* <Route path="/" exact component={Home} />
        <Route path="/oidc/callback/" component={LoginCallback} />         */}
        <Route path="/" element={<Home />} />
        <Route path="/oidc/callback/" element={<LoginCallback />} />
      </Routes>
      </Suspense>
    </Security>
    
  );
};

export default SSO;
