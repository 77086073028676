import * as types from "./qteTypes";

export const fetchQteDetStart = (payload) => ({
  type: types.FETCH_QTE_DET_START,
  payload,
});

export const fetchQteDetSucc = (payload) => ({
  type: types.FETCH_QTE_DET_SUCCESS,
  payload,
});

export const fetchQteDetErr = (error) => ({
  type: types.FETCH_QTE_DET_ERROR,
  payload: error,
});

export const updQteDetStart = (payload) => ({
  type: types.UPD_QTE_DET_START,
  payload,
});

export const updQteDetSucc = (payload) => ({
  type: types.UPD_QTE_DET_SUCCESS,
  payload,
});
export const updQteDetErr = (error) => ({
  type: types.UPD_QTE_DET_ERROR,
  payload: error,
});

export const applyMpadjsStart = (payload) => ({
  type: types.APPLY_MASSADJ_START,
  payload,
});

export const applyMpadjsSucc = (payload) => ({
  type: types.APPLY_MASSADJ_SUCCESS,
  payload,
});

export const applyMpadjsErr = (error) => ({
  type: types.APPLY_MASSADJ_ERROR,
  payload: error,
});

export const rqstIdStart = (payload) => ({
  type: types.REFRESH_CONC_START,
  payload,
});

export const rqststatSucc = (payload) => ({
  type: types.REFRESH_CONC_SUCCESS,
  payload,
});

export const rqststatErr = (error) => ({
  type: types.REFRESH_CONC_ERROR,
  payload: error,
});

export const deleteQteAdj = (payload) => (
  {
    type: types.DELETE_QTE_ADJ_START,
    payload
  }
)

export const deleteQteAdjSucc = (payload) => (
  {
    type: types.DELETE_QTE_ADJ_SUCCESS,
    payload
  }
)

export const deleteQteAdjFailure = (error) => (
  {
    type: types.DELETE_QTE_ADJ_FAILURE,
    payload: error
  }
)

export const getSelAdj = (payload) => ({
  type: types.GET_SELSECTED_ADJ,
  payload
})

export const fetchOrderDet = (payload) => ({
  type: types.FETCH_ODR_START,
  payload
})

export const fetchOrderDetSucc = (payload) => ({
  type: types.FETCH_ODR_SUCCESS,
  payload
})

export const fetchOrderDetErr = (error) => ({
  type: types.FETCH_ODR_ERROR,
  payload: error
})

export const updateOrderStart = (payload, isDelete, sordernbr, sorgname) => {
  return ({
    type: types.UPD_ODR_START,
    payload,
    isDelete,
    sordernbr,
    sorgname
  })
}

export const updateOrderSucc = (payload) => ({
  type: types.UPD_ODR_SUCCESS,
  payload
})

export const updateOrderErr = (payload) => ({
  type: types.UPD_ODR_ERROR,
  payload
})

export const refreshOrderStart = (payload) => ({
  type: types.REFRESH_ODR_START,
  payload
})

export const refreshOrderSucc = () => ({
  type: types.REFRESH_ODR_SUCCESS,
})

export const refreshOrderErr = (payload) => ({
  type: types.REFRESH_ODR_ERROR,
  payload
})
