import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

const LogoutIcon = (props) => {
  return (
    <SvgIcon {...props}
    sx={{ transform:"rotate(270deg)", color:"#fff", fontSize:"25px" }}
    >
     <svg
    viewBox="0 0 24 24"
    transform="rotate(270 0 0)"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2a9.985 9.985 0 0 1 8 4h-2.71a8 8 0 1 0 .001 12h2.71A9.985 9.985 0 0 1 12 22zm7-6v-3h-8v-2h8V8l5 4-5 4z" />
    </g>
  </svg>
    </SvgIcon>
  );
};

export default LogoutIcon;