import React from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const StyledIconButton = styled(IconButton)({
    borderRadius: '50%',
    height: '26px',
    width: '26px',
    fontSize: '15px',
    backgroundColor: 'transparent',
    padding:'0px',
    color: '#00aa7e' ,
    // '& svg': {
    //     fontSize: '22px'
    // },
    '&:hover': {
        color: '#00aa7e',
        backgroundColor: 'transparent',
        cursor:'pointer',
        // filter: icon ? 'none' : 'brightness(1.2)',
        // cursor: icon ? 'default' : 'pointer'
    },
    '&.Mui-disabled': {
        backgroundColor: 'transparent',
        color: '#666979'
    }
  });

const CustomIconButton = (props) => {
    const {children, onClick, disabled, ...rest} = props;
  return (
    <StyledIconButton
    {...rest}
    onClick={onClick}
    disabled={disabled ? disabled : false}
    >
        {children}
    </StyledIconButton>
  )
}

export default CustomIconButton