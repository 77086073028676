import * as types from "./searchTypes";
 //const payload ={sorgId:"",showQuote:"",showOrder:""}

/*export const isQuote = (payload) => {
  let stype;
  if (payload === "Quote") {
    stype = types.IS_QUOTE;
  } else if (payload === "Order") {
    stype = types.IS_ORDER;
  } else stype = "default";

  return {
    type: stype,
    payload: payload,
  };*/
 
  export const loadOUStart = (payload) => ({      
  type: types.GET_OU_START,
  payload: payload
});

export const loadOUSuccess = (ous,sorgId,sorgname,squoteno,showQuote,showOrder, squotever, sordernbr) => ({

  type: types.GET_OU_SUCCESS,
  ous: ous,
  sorgId: sorgId,
  sorgname: sorgname,
  squoteno: squoteno,
  showQuote: showQuote,
  showOrder: showOrder,
  squotever: squotever,
  sordernbr: sordernbr,
});

export const loadOUFailure = (error) => ({
  type: types.GET_OU_FAILURE,
  payload: error,
});

export const loadAdjStart = (payload) => ({      
  type: types.GET_ADJ_START,
  payload: payload
});

export const loadAdjSuccess = (adjs,sorgId) => ({
  type: types.GET_ADJ_SUCCESS,
  adjs: adjs,
  sorgId: sorgId,
});

export const loadAdjFailure = (error) => ({
  type: types.GET_ADJ_FAILURE,
  payload: error,
});

export const setEmail = (email) => (   {
  type: types.SET_EMAIL,
  email,
});

export const setAccord = (accordState) => ({
  type : types.SET_ACCORD,
  showAccord : accordState,
})