import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./saga";
import rootReducer from "./rootReducer";

const sagaMiddleware = createSagaMiddleware();
//const middleware = [logger,sagaMiddleware]
const middleware = [sagaMiddleware];
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
//const store = createStore(rootReducer,composeWithDevTools(applyMiddleware(logger)))
sagaMiddleware.run(rootSaga);

export default store;
