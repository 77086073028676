//import React from "react";
//import ReactDOM from "react-dom";
import './index.scss';
//import App from "./App";
//import App1 from "./components/sso/App1"
//import reportWebVitals from './reportWebVitals';
//import { Provider } from "react-redux";
//import store from './store/store'

import "react-app-polyfill/ie9";
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
//import 'semantic-ui-css/semantic.min.css';
//import './components/sso/polyfills';
import { Provider } from "react-redux";
import store from "./redux/store";
import SSO from "./components/sso/SSO"
import config from "./components/sso/config";
//import './index.css';


/*ReactDOM.render(
  <React.StrictMode>
      <App1 />
  </React.StrictMode>,
  document.getElementById("root")
);*/

ReactDOM.render(
  <Router basename={config.app.basename}>
    <Provider store={store}>
    <SSO />
    </Provider> 
  </Router>,
  document.getElementById('root'),
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
